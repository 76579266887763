import * as Configuration from './configuration'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'

import {Helmet} from 'react-helmet-async'
import querystring from 'querystring'

const acceptableQueries = {
  'login': 'me',
  'register': 'now',
  'password-recovery': 'email',
  // Tokens are different
  'password-reset': '*',
  // game ids are different
  'gameId': '*',
}

function getQuery(query) {
  const foundAcceptableQueries = R.pick(R.keys(acceptableQueries), query)
  const firstAcceptableQueryKey = R.head(R.keys(foundAcceptableQueries))

  const queryValue = foundAcceptableQueries[firstAcceptableQueryKey]
  const acceptableQueryValue = acceptableQueries[firstAcceptableQueryKey]

  if (R.isEmpty(foundAcceptableQueries) || R.isNil(foundAcceptableQueries)) {
    return ''
  }

  if (queryValue !== acceptableQueryValue) {
    if (acceptableQueryValue === '*') {
      return `?${firstAcceptableQueryKey}=${queryValue}`
    }

    return `?${firstAcceptableQueryKey}=${acceptableQueryValue}`
  }

  return `?${firstAcceptableQueryKey}=${queryValue}`
}

export function SeoCanonicals() {
  const location = ReactRouterDom.useLocation()

  const origin = ReactRedux.useSelector((state) =>
    Configuration.getOrigin(state.configuration)
  )

  // Kill trailing slashes
  const pathname = R.dropLastWhile(
    (lastChar) => R.equals('/', lastChar),
    location.pathname
  )

  // Get allowed queries
  const query = getQuery(querystring.parse(R.drop(1, location.search)))

  return (
    <Helmet>
      <link rel="canonical" href={`${origin}${pathname}${query}`} />
    </Helmet>
  )
}

export default SeoCanonicals
